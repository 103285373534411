import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * DatePickerCalendar 아이콘
 * @param props
 * @returns
 */
export function DatePickerCalendarIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.25C8.41421 2.25 8.75 2.58579 8.75 3V4.25H15.25V3C15.25 2.58579 15.5858 2.25 16 2.25C16.4142 2.25 16.75 2.58579 16.75 3V4.25H18C18.7293 4.25 19.4288 4.53973 19.9445 5.05546C20.4603 5.57118 20.75 6.27065 20.75 7V19C20.75 19.7293 20.4603 20.4288 19.9445 20.9445C19.4288 21.4603 18.7293 21.75 18 21.75H6C5.27065 21.75 4.57118 21.4603 4.05546 20.9445C3.53973 20.4288 3.25 19.7293 3.25 19V7C3.25 6.27065 3.53973 5.57118 4.05546 5.05546C4.57118 4.53973 5.27065 4.25 6 4.25H7.25V3C7.25 2.58579 7.58579 2.25 8 2.25ZM7.25 5.75H6C5.66848 5.75 5.35054 5.8817 5.11612 6.11612C4.8817 6.35054 4.75 6.66848 4.75 7V10.25H19.25V7C19.25 6.66848 19.1183 6.35054 18.8839 6.11612C18.6495 5.8817 18.3315 5.75 18 5.75H16.75V7C16.75 7.41421 16.4142 7.75 16 7.75C15.5858 7.75 15.25 7.41421 15.25 7V5.75H8.75V7C8.75 7.41421 8.41421 7.75 8 7.75C7.58579 7.75 7.25 7.41421 7.25 7V5.75ZM19.25 11.75H4.75V19C4.75 19.3315 4.8817 19.6495 5.11612 19.8839C5.35054 20.1183 5.66848 20.25 6 20.25H18C18.3315 20.25 18.6495 20.1183 18.8839 19.8839C19.1183 19.6495 19.25 19.3315 19.25 19V11.75Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default DatePickerCalendarIcon;
