import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 로그아웃 오른쪽 방향 화살표 아이콘
 * @param props
 * @returns
 */
export function LogOutRightIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.05546 4.05546C3.57118 3.53973 4.27065 3.25 5 3.25H12C12.7293 3.25 13.4288 3.53973 13.9445 4.05546C14.4603 4.57118 14.75 5.27065 14.75 6V8C14.75 8.41421 14.4142 8.75 14 8.75C13.5858 8.75 13.25 8.41421 13.25 8V6C13.25 5.66848 13.1183 5.35054 12.8839 5.11612C12.6495 4.8817 12.3315 4.75 12 4.75H5C4.66848 4.75 4.35054 4.8817 4.11612 5.11612C3.8817 5.35054 3.75 5.66848 3.75 6V18C3.75 18.3315 3.8817 18.6495 4.11612 18.8839C4.35054 19.1183 4.66848 19.25 5 19.25H12C12.3315 19.25 12.6495 19.1183 12.8839 18.8839C13.1183 18.6495 13.25 18.3315 13.25 18V16C13.25 15.5858 13.5858 15.25 14 15.25C14.4142 15.25 14.75 15.5858 14.75 16V18C14.75 18.7293 14.4603 19.4288 13.9445 19.9445C13.4288 20.4603 12.7293 20.75 12 20.75H5C4.27065 20.75 3.57118 20.4603 3.05546 19.9445C2.53973 19.4288 2.25 18.7293 2.25 18V6C2.25 5.27065 2.53973 4.57118 3.05546 4.05546ZM17.4697 8.46967C17.7626 8.17678 18.2374 8.17678 18.5303 8.46967L21.5303 11.4697C21.8232 11.7626 21.8232 12.2374 21.5303 12.5303L18.5303 15.5303C18.2374 15.8232 17.7626 15.8232 17.4697 15.5303C17.1768 15.2374 17.1768 14.7626 17.4697 14.4697L19.1893 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H19.1893L17.4697 9.53033C17.1768 9.23744 17.1768 8.76256 17.4697 8.46967Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default LogOutRightIcon;
