import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 내부가 흰 색으로 채워진 메일 아이콘
 * @param props
 * @returns
 */
export function MailSolidIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 36 36">
      <g id="email">
        <g id="Vector">
          <path
            d="M4.5 10.5C4.5 9.70435 4.81607 8.94129 5.37868 8.37868C5.94129 7.81607 6.70435 7.5 7.5 7.5H28.5C29.2956 7.5 30.0587 7.81607 30.6213 8.37868C31.1839 8.94129 31.5 9.70435 31.5 10.5V25.5C31.5 26.2956 31.1839 27.0587 30.6213 27.6213C30.0587 28.1839 29.2956 28.5 28.5 28.5H7.5C6.70435 28.5 5.94129 28.1839 5.37868 27.6213C4.81607 27.0587 4.5 26.2956 4.5 25.5V10.5Z"
            fill="white"
          />
          <path d="M4.5 10.5L18 19.5L31.5 10.5" fill="white" />
          <path
            d="M4.5 10.5C4.5 9.70435 4.81607 8.94129 5.37868 8.37868C5.94129 7.81607 6.70435 7.5 7.5 7.5H28.5C29.2956 7.5 30.0587 7.81607 30.6213 8.37868C31.1839 8.94129 31.5 9.70435 31.5 10.5M4.5 10.5V25.5C4.5 26.2956 4.81607 27.0587 5.37868 27.6213C5.94129 28.1839 6.70435 28.5 7.5 28.5H28.5C29.2956 28.5 30.0587 28.1839 30.6213 27.6213C31.1839 27.0587 31.5 26.2956 31.5 25.5V10.5M4.5 10.5L18 19.5L31.5 10.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </IconContainer>
  );
}

export default MailSolidIcon;
