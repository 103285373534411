import { IconCommonProps } from '../types';
import IconContainer from '../IconContainer';

export function ImageDeleteIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="16"
          height="15.9553"
          rx="4"
          fill="black"
          fill-opacity="0.5"
        ></rect>
        <path
          d="M11.1595 11.955L8.03102 8.82652L4.90225 11.9553L4.10676 11.1598L7.23553 8.03102L4 4.7955L4.7955 4L8.03102 7.23553L11.2662 4.00033L12.0617 4.79583L8.82652 8.03102L11.955 11.1595L11.1595 11.955Z"
          fill="white"
        ></path>
      </svg>
    </IconContainer>
  );
}

export default ImageDeleteIcon;
