import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Box 아이콘
 * @param props
 * @returns
 */
export function BoxIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M21.5322 7.70009H2.46783M14.9996 11.0001C12.6565 11.0001 8.9996 11.0001 8.9996 11.0001M21.6 8.26665V19.7001C21.6 21.0256 20.5255 22.1001 19.2 22.1001H4.79999C3.47451 22.1001 2.39999 21.0256 2.39999 19.7001V8.26665C2.39999 7.89406 2.48674 7.52659 2.65337 7.19334L4.30249 3.8951C4.60739 3.28529 5.23067 2.90009 5.91246 2.90009H18.0875C18.7693 2.90009 19.3926 3.28529 19.6975 3.8951L21.3466 7.19334C21.5132 7.52659 21.6 7.89406 21.6 8.26665Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default BoxIcon;
