import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Download 아이콘
 * @param props
 * @returns
 */
export function DownloadIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M12.9 3.2999C12.9 2.80285 12.4971 2.3999 12 2.3999C11.5029 2.3999 11.1 2.80285 11.1 3.2999V13.6362L7.55431 9.88194C7.21302 9.52058 6.6434 9.5043 6.28204 9.84559C5.92067 10.1869 5.90439 10.7565 6.24568 11.1179L11.3457 16.5179C11.5157 16.6979 11.7524 16.7999 12 16.7999C12.2476 16.7999 12.4843 16.6979 12.6543 16.5179L17.7543 11.1179C18.0956 10.7565 18.0793 10.1869 17.718 9.84559C17.3566 9.5043 16.787 9.52058 16.4457 9.88194L12.9 13.6362V3.2999Z"
        fill="currentColor"
      />
      <path
        d="M4.19999 15.2999C4.19999 14.8028 3.79705 14.3999 3.29999 14.3999C2.80294 14.3999 2.39999 14.8028 2.39999 15.2999V18.2999C2.39999 20.1224 3.87745 21.5999 5.69999 21.5999H18.3C20.1225 21.5999 21.6 20.1224 21.6 18.2999V15.2999C21.6 14.8028 21.1971 14.3999 20.7 14.3999C20.2029 14.3999 19.8 14.8028 19.8 15.2999V18.2999C19.8 19.1283 19.1284 19.7999 18.3 19.7999H5.69999C4.87157 19.7999 4.19999 19.1283 4.19999 18.2999V15.2999Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default DownloadIcon;
