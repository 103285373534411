import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 오픈북 아이콘
 * @param props
 * @returns
 */
export function Bars2SlashIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <g xmlns="http://www.w3.org/2000/svg" id="bars-2-light-slice">
        <path
          id="Rectangle 34624248"
          d="M8.17322 19.4032C7.74276 19.1547 7.59527 18.6043 7.8438 18.1738L14.9438 5.87623C15.1923 5.44577 15.7428 5.29828 16.1732 5.54681C16.6037 5.79534 16.7512 6.34577 16.5026 6.77623L9.40264 19.0738C9.15412 19.5043 8.60368 19.6517 8.17322 19.4032Z"
          fill="currentColor"
        />
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1876 16.65H18.014C18.511 16.65 18.914 16.2471 18.914 15.75C18.914 15.2529 18.511 14.85 18.014 14.85H13.2268L12.1876 16.65ZM16.0847 9.90001H18.014C18.511 9.90001 18.914 9.49706 18.914 9.00001C18.914 8.50295 18.511 8.10001 18.014 8.10001H17.1239L16.0847 9.90001ZM15.7383 8.10001L14.6991 9.90001H12.6206L13.6598 8.10001H15.7383ZM12.2742 8.10001H5.98594C5.48888 8.10001 5.08594 8.50295 5.08594 9.00001C5.08594 9.49706 5.48888 9.90001 5.98594 9.90001H11.235L12.2742 8.10001ZM8.37709 14.85H5.98594C5.48888 14.85 5.08594 15.2529 5.08594 15.75C5.08594 16.2471 5.48888 16.65 5.98594 16.65H7.33786L8.37709 14.85ZM10.802 16.65H8.7235L9.76273 14.85H11.8412L10.802 16.65Z"
          fill="currentColor"
        />
      </g>
    </IconContainer>
  );
}

export default Bars2SlashIcon;
