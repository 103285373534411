import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 복사 아이콘
 * @param props
 * @returns
 */
export function CopyIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <rect
        x="3.8"
        y="5.8"
        width="12.4"
        height="15.4"
        rx="2.2"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path
        d="M12 2H17C18.6569 2 20 3.34315 20 5V11"
        stroke="currentColor"
        strokeWidth="1.6"
      />
    </IconContainer>
  );
}

export default CopyIcon;
